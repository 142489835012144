<!--
 * @Author: ocean
 * @Description: 数据统计 运营数据统计 流水统计列表
-->
<template>
  <scroll-layout class="flowStatistics-list">
    <template slot="header">
      <ykc-warm-tip
        type="warning"
        v-if="topTipStatus"
        :description="topTip"
        @click="topTipStatus = false"></ykc-warm-tip>
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParam"
        @clean="handleSearchClean"
        @click="handleSearchConfirm"></ykc-search>
    </template>
    <!-- <div class="tags" v-show="switchOver">
      报表统计口径说明
      <span @click="tagClick()">?</span>
    </div> -->
    <div v-if="!switchOver">
      <div class="but-box">
        <ykc-button type="plain" @click="switchOverFn(1)">报表模式</ykc-button>
      </div>
      <statistics-chart
        ref="statisticsChart"
        :page="'platform'"
        :title="'充电趋势'"
        :isUpdate="isUpdate"
        :searchParams="searchParam"></statistics-chart>
    </div>
    <div v-if="switchOver">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button v-rbac="'data:platform:flow:export'" type="plain" @click="doExportFile">
              导出
            </ykc-button>
            <ykc-button type="plain" @click="switchOverFn(2)" v-if="false">图表模式</ykc-button>
          </div>
        </template>
        <ykc-pagination
          align="right"
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>
<script>
  import { getLastYear, offlineExport, code } from '@/utils';
  import { dataStatisticsServe } from '@/service/apis';

  import statisticsChart from '../../components/dataStatisticsChart.vue';

  export default {
    name: 'flowStatisticsList',
    components: {
      statisticsChart,
    },
    data() {
      return {
        switchOver: true,
        topTip:
          '温馨提示：由于统计数据量过大，默认显示近6月统计数据，需要查看其他数据请使用查询功能。',
        tradeNo: null,
        isUpdate: false,
        topTipStatus: true,
        operationStatus: false,
        searchParam: {
          date: {},
          endTime: '',
          startTime: '',
          dateType: '2',
        },
        tableTitle: '明细列表', // 列表表格
        tableData: [],
        tableOperateButtons: [
          {
            id: '1',
            enabled: () => code('finance:account:cacc:detail'),
            text: '详情',
            handleClick: (btn, { row }) => {
              this.$router.push({
                path: '/dataStatistics/operationStatistics/flowStatistics/detail',
                query: { date: row.timeFrame },
              });
            },
          },
        ], // 列表操作
        tableColumns: [
          { label: '时间维度', prop: 'timeFrame', fixed: true, id: 0 },
          { label: '充值总金额(元)', prop: 'rechargeAmount', id: 1 },
          { label: '退款总金额(元)', prop: 'refundAmount', id: 2 },
          { label: '用户实付(元)', prop: 'actualCustomerPayment', id: 3 },
          { label: '占位费实付(元)', prop: 'occupancyActualAmount', id: 4 },
          { label: '占位费退款(元)', prop: 'occupancyRefund', id: 5 },
        ],
        pageInfo: {
          current: 1,
          size: 10,
        },
      };
    },
    created() {
      this.setInitDateFn();
      this.requestList();
    },
    computed: {
      searchData() {
        return [
          {
            key: 'date',
            type: 'monthrange',
            label: '时间维度',
            comName: 'YkcDatePicker',
          },
        ];
      },
    },
    methods: {
      /**
       * 请求列表数据
       */
      requestList() {
        const pageInfos = (({ current, size }) => ({ current, size }))(this.pageInfo);
        const reqParams = {
          ...pageInfos,
          endTime: this.searchParam.endTime,
          startTime: this.searchParam.startTime,
          dateType: this.searchParam.dateType,
        };
        dataStatisticsServe
          .queryFlowMonthList(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total || 0;
          })
          .catch(err => {
            console.log(err);
          });
      },
      /**
       * 统计说明
       */
      tagClick() {
        this.operationStatus = true;
      },
      /**
       * 导出
       */
      doExportFile() {
        offlineExport({
          downloadType: 'flow_statistics_export',
          jsonNode: {
            ...this.searchParam,
          },
        });
      },
      /**
       * 点击筛选查询按钮
       * */
      handleSearchConfirm(searchData) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParam, searchData);

        // 时间选择
        if (searchData.date && Array.isArray(searchData.date) && searchData.date.length > 0) {
          [this.searchParam.startTime, this.searchParam.endTime] = searchData.date;
        }

        this.switchOver ? this.requestList() : this.$refs.statisticsChart.getCharts();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParam, form);

        this.setInitDateFn();
      },
      /**
       * 设置初始化1-6个默认时间查询
       */
      setInitDateFn() {
        const monthStr = getLastYear(6, 1);
        const startDateStr = monthStr.startTime;
        const endDateStr = monthStr.endTime;

        this.searchParam.date = [startDateStr, endDateStr];
        this.searchParam.startTime = startDateStr;
        this.searchParam.endTime = endDateStr;
      },
      /**
       * 分页器页数
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.requestList();
      },
      /**
       * 分页器条数
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.requestList();
      },
      /**
       * 切换到报表或图表模式
       * @param {*} type 1：报表 2：图表
       */
      switchOverFn(type) {
        switch (type) {
          case 1:
            this.switchOver = true;
            this.requestList();
            break;
          case 2:
            this.switchOver = false;
            break;

          default:
            break;
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .ykc-checkbox .icon-check {
    top: 7px;
  }

  .but-box {
    display: flex;
    justify-content: flex-end;
  }
  .flowStatistics-list {
    .tags {
      width: 160px !important;
      min-width: 0 !important;
      display: flex;
      align-items: center;
      font-size: 12px;
      position: relative;
      top: 35px;
      left: 85px;
      > span {
        display: block;
        width: 16px;
        height: 16px;
        margin-left: 8px;
        font-size: 10px;
        line-height: 16px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        background: blue;
      }
    }

    .ykc-table {
      .el-table .el-table__row {
        .cell .userStatus {
          padding-left: 8px;
          position: relative;
        }

        .cell .userStatus::after {
          display: block;
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: #d0021b;
          position: absolute;
          top: 5.5px;
          left: 0;
        }

        .cell .status-blue::after {
          background: #4d8cfd;
        }

        .cell .status-green::after {
          background: #02c498;
        }

        .cell .status-black::after {
          background: #9b9b9b;
        }
      }

      .sealBtn {
        color: #4d8cfd;
        cursor: pointer;
      }
    }
  }
</style>
